import { ApiResponse } from '../models/ApiResponse';
import BaseService from './BaseService';
import { Training, TrainingCreate } from '../models/Training';

class TrainingService extends BaseService {
  public static getTraining(id: string): Promise<ApiResponse<Training>> {
    return this.get(`/v1/training/${id}`);
  }

  public static getTrainingPaged(clientId: string): Promise<ApiResponse<Training[]>> {
    return this.get(`/v1/training/paged`, { params: { clientId } });
  }

  public static createTraining(training: TrainingCreate): Promise<ApiResponse<Training>> {
    return this.post(`/v1/training`, training);
  }

  public static updateTraining(id: string, training: TrainingCreate): Promise<ApiResponse<Training>> {
    return this.put(`/v1/training/${id}`, training);
  }
}

export default TrainingService;
